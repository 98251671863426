import { Component, OnInit } from '@angular/core'
import {
  LatestDataModel,
  ReleaseDataService,
  TopCountryItem,
  TopPortItem,
  TopRoutesInterval,
  TopRoutesMeasure,
  TopRoutesTable
} from '@cts/cedar-public-api'

type ImexDirection = 'import' | 'export'
type SortDirection = 'asc' | 'desc'
type TopRoutesSort = 'from' | 'to' | 'value'
type KpiSort = 'data.mom' | 'data.yoy' | 'data.ytd'
type TopCountriesSort = 'nameSortOrdinal' | KpiSort
type TopPortsSort = 'countryName' | 'nameSortOrdinal' | KpiSort

@Component({
  selector: 'app-latest-data',
  templateUrl: './latest-data.component.html',
  styleUrls: ['./latest-data.component.scss']
})
export class LatestDataComponent implements OnInit {
  public TopRoutesMeasure: typeof TopRoutesMeasure = TopRoutesMeasure
  public TopRoutesInterval: typeof TopRoutesInterval = TopRoutesInterval

  public model: LatestDataModel | null = null

  public uiState = {
    topRisersSelection: null as TopRoutesTable | null,
    topRisersSort: null as TopRoutesSort | null,
    topRisersSortDirection: null as SortDirection | null,

    topFallersSelection: null as TopRoutesTable | null,
    topFallersSort: null as TopRoutesSort | null,
    topFallersSortDirection: null as SortDirection | null,

    topCountriesSelection: null as ImexDirection | null,
    topCountriesSort: null as TopCountriesSort | null,
    topCountriesSortDirection: null as SortDirection | null,
    topCountriesTable: null as TopCountryItem[] | null,

    topPortsSelection: null as ImexDirection | null,
    topPortsSort: null as TopPortsSort | null,
    topPortsSortDirection: null as SortDirection | null,
    topPortsTable: null as TopPortItem[] | null
  }

  constructor(private releaseDataService: ReleaseDataService) {}

  ngOnInit(): void {
    this.releaseDataService.getLatestDataModel().subscribe((model) => {
      this.model = model

      var topCountriesTableSet = [model.topCountries?.exportItems, model.topCountries?.importItems]
      var topPortsTableSet = [model.topPorts?.exportItems, model.topPorts?.importItems]

      this.uiState = {
        ...this.uiState,
        ...{
          topRisersSelection: firstTopRoutesOrDefault(model.topRisers),
          topFallersSelection: firstTopRoutesOrDefault(model.topFallers),
          topCountriesTable: firstOrDefault(topCountriesTableSet),
          topPortsTable: firstOrDefault(topPortsTableSet)
        }
      }

      this.uiState.topCountriesSelection =
        this.uiState.topCountriesTable === model.topCountries.exportItems
          ? 'export'
          : this.uiState.topCountriesTable === model.topCountries.importItems
            ? 'import'
            : null

      this.uiState.topPortsSelection =
        this.uiState.topPortsTable === model.topPorts.exportItems
          ? 'export'
          : this.uiState.topPortsTable === model.topPorts.importItems
            ? 'import'
            : null

      model.topRisers.forEach((table) => table.items.sort((a, b) => b.value - a.value))
      model.topFallers.forEach((table) => table.items.sort((a, b) => a.value - b.value))

      topCountriesTableSet.forEach((table) =>
        table.sort((b, a) => b.nameSortOrdinal - a.nameSortOrdinal)
      )
      topPortsTableSet.forEach((table) =>
        table.sort((b, a) => b.nameSortOrdinal - a.nameSortOrdinal)
      )
    })

    function firstOrDefault<K>(source: K[]): K | null {
      if (source) {
        return source.find((k) => k) || null
      } else return null
    }

    function firstTopRoutesOrDefault(source: TopRoutesTable[]): TopRoutesTable | null {
      if (!source) return null
      return (
        source.find(
          (item) =>
            item.intervalType === TopRoutesInterval.Mom &&
            item.measureType === TopRoutesMeasure.Volumes
        ) ||
        source.find((item) => item) ||
        null
      )
    }
  }

  toggleTopRisersMeasureType(measureType: TopRoutesMeasure): void {
    var selection = this.uiState.topRisersSelection
    var interval =
      measureType == TopRoutesMeasure.PriceIndices &&
      selection!.intervalType == TopRoutesInterval.Ytd
        ? TopRoutesInterval.Mom
        : selection!.intervalType
    if (selection) {
      var newSelection = this.model!.topRisers.find(
        (table) => table.intervalType == interval && table.measureType == measureType
      )
      if (newSelection && newSelection !== selection) this.uiState.topRisersSelection = newSelection
    }
  }

  toggleTopRisersIntervalType(intervalType: TopRoutesInterval): void {
    var selection = this.uiState.topRisersSelection
    if (selection) {
      var newSelection = this.model!.topRisers.find(
        (table) => table.measureType == selection!.measureType && table.intervalType == intervalType
      )
      if (newSelection && newSelection !== selection) this.uiState.topRisersSelection = newSelection
    }
  }

  sortTopRisersItems(attribute: TopRoutesSort): void {
    var sortDirection = this.uiState.topRisersSortDirection
    if (this.uiState.topRisersSort !== attribute) {
      this.uiState.topRisersSort = attribute
      sortDirection = 'asc'
    } else {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    this.uiState.topRisersSortDirection = sortDirection
    var sense = sortDirection === 'asc' ? 1 : -1

    this.uiState.topRisersSelection!.items.sort((a, b) => {
      var aProp: any = (a as any)[attribute]
      var bProp: any = (b as any)[attribute]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })
  }

  toggleTopFallersMeasureType(measureType: TopRoutesMeasure): void {
    var selection = this.uiState.topFallersSelection
    var interval =
      measureType == TopRoutesMeasure.PriceIndices &&
      selection!.intervalType == TopRoutesInterval.Ytd
        ? TopRoutesInterval.Mom
        : selection!.intervalType
    if (selection) {
      var newSelection = this.model!.topFallers.find(
        (table) => table.intervalType == interval && table.measureType == measureType
      )
      if (newSelection && newSelection !== selection)
        this.uiState.topFallersSelection = newSelection
    }
  }

  toggleTopFallersIntervalType(intervalType: TopRoutesInterval): void {
    var selection = this.uiState.topFallersSelection
    if (selection) {
      var newSelection = this.model!.topFallers.find(
        (table) => table.measureType == selection!.measureType && table.intervalType == intervalType
      )
      if (newSelection && newSelection !== selection)
        this.uiState.topFallersSelection = newSelection
    }
  }

  sortTopFallersItems(attribute: TopRoutesSort): void {
    var sortDirection = this.uiState.topFallersSortDirection
    if (this.uiState.topFallersSort !== attribute) {
      this.uiState.topFallersSort = attribute
      sortDirection = 'asc'
    } else {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    this.uiState.topFallersSortDirection = sortDirection
    var sense = sortDirection === 'asc' ? 1 : -1

    this.uiState.topFallersSelection!.items.sort((a, b) => {
      var aProp: any = (a as any)[attribute]
      var bProp: any = (b as any)[attribute]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })
  }

  toggleTopCountriesImexDirection(): void {
    var selection = this.uiState.topCountriesSelection
    if (selection) {
      var newSelection: ImexDirection | null = null
      var newTable: TopCountryItem[] | null = null

      switch (selection) {
        case 'import':
          newTable = this.model!.topCountries.exportItems
          newSelection = 'export'
          break
        case 'export':
          newTable = this.model!.topCountries.importItems
          newSelection = 'import'
          break
      }

      if (newTable) {
        this.uiState.topCountriesSelection = newSelection
        this.uiState.topCountriesTable = newTable
      }
    }
  }

  toggleTopPortsImexDirection(): void {
    var selection = this.uiState.topPortsSelection
    if (selection) {
      var newSelection: ImexDirection | null = null
      var newTable: TopPortItem[] | null = null

      switch (selection) {
        case 'import':
          newTable = this.model!.topPorts.exportItems
          newSelection = 'export'
          break
        case 'export':
          newTable = this.model!.topPorts.importItems
          newSelection = 'import'
          break
      }

      if (newTable) {
        this.uiState.topPortsSelection = newSelection
        this.uiState.topPortsTable = newTable
      }
    }
  }

  sortTopCountriesItems(attribute: TopCountriesSort): void {
    var sortDirection = this.uiState.topCountriesSortDirection
    if (this.uiState.topCountriesSort !== attribute) {
      this.uiState.topCountriesSort = attribute
      sortDirection = 'asc'
    } else {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    this.uiState.topCountriesSortDirection = sortDirection
    var sense = sortDirection === 'asc' ? 1 : -1

    this.uiState.topCountriesTable!.sort((a, b) => {
      var aProp: any = a
      var bProp: any = b
      var propertyName = attribute as string
      if (propertyName.startsWith('data.')) {
        aProp = a.data
        bProp = b.data
        propertyName = attribute.substring('data.'.length)
      }

      aProp = aProp[propertyName]
      bProp = bProp[propertyName]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })
  }

  sortTopPortsItems(attribute: TopPortsSort): void {
    var sortDirection = this.uiState.topPortsSortDirection
    if (this.uiState.topPortsSort !== attribute) {
      this.uiState.topPortsSort = attribute
      sortDirection = 'asc'
    } else {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    this.uiState.topPortsSortDirection = sortDirection
    var sense = sortDirection === 'asc' ? 1 : -1

    this.uiState.topPortsTable!.sort((a, b) => {
      var aProp: any = a
      var bProp: any = b
      var propertyName = attribute as string
      if (propertyName.startsWith('data.')) {
        aProp = a.data
        bProp = b.data
        propertyName = attribute.substring('data.'.length)
      }

      aProp = aProp[propertyName]
      bProp = bProp[propertyName]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })
  }
}
